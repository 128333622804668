




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { CHANGE_PLACEMENT } from '../../../store/actions';
import { ChangePlacement, ConservatorshipDetails, Placement } from '../../../types';
import DatePicker from '@/components/form/DatePicker.vue';
import { ApiResponse } from '@/components/types';

const Conservatorship = namespace('conservatorship');

@Component({
  components: {
    DatePicker
  }
})
export default class PlacementChangeDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Conservatorship.Action(CHANGE_PLACEMENT) updatePlacement!: (params: ChangePlacement) => Promise<ApiResponse>;

  HOME: Placement = 'home';
  ASYLUM: Placement = 'asylum';

  dialog: boolean = false;
  error: any = null;

  value: ChangePlacement = {
    conservatorship_id: this.conservatorship.id,
    placement: this.newState(this.conservatorship.conservatee.placement),
    changed: format(Date.now(), 'yyyy-MM-dd'),
  };

  newState(placement: Placement): Placement {
    if (placement === this.HOME) {
      return this.ASYLUM;
    }

    return this.HOME;
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        conservatorship_id: this.conservatorship.id,
        placement: this.newState(this.conservatorship.conservatee.placement),
        changed: format(Date.now(), 'yyyy-MM-dd'),
      };

      return;
    }

    setTimeout(() => {
      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;
    }, 200);
  }

  save() {
    this.error = null;

    return this.updatePlacement(this.value);
  }
}
